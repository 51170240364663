<template>
    <div id="app">
      <main class="main" id="top">
        <div class="container-fluid px-0" data-layout="container">
          <notifications  group="iasc" />
          <router-view />
        </div>
      </main>
    </div>
  </template>
<script>
  
  export default {
    data(){
      return{
        year: new Date().getFullYear(),
      }
    }
  }
  </script>